<template>
    <!-- <form action="" @submit.prevent="onCreatePost">
        <input type="text" name="fname" placeholder="name" v-model="fname" required>
        <input type="text" name="lname" placeholder="lastname" v-model="lname" required>
        <input type="phone" name="phonenumber" placeholder="phonenumber" v-model="phonenumber" required>
        <button type="submit">send</button>
    </form> -->
    <div class="container-form">
        <!-- <form @submit.prevent="sendEmail">
          <label>Name</label>
          <input 
            type="text" 
            v-model="name"
            name="name"
            placeholder="Your Name"
          >
          <label>Email</label>
          <input 
            type="email" 
            v-model="email"
            name="email"
            placeholder="Your Email"
            >
          <label>Message</label>
          <textarea 
            name="message"
            v-model="message"
            cols="30" rows="5"
            placeholder="Message">
          </textarea>
          
          <input type="submit" value="Send">
        </form> -->
    </div>
</template>
<script>
// import emailjs from 'emailjs-com';
// const axios = require("axios");
// const API_KEY = "1755cefee95e88207607aa48b9f0b47f52210f17402b4c457b66a1687861f6e9";
export default {
    // name: 'FormTest',
    // data() {
    //     return {
    //         name: '',
    //         email: '',
    //         message: '',
    //     }
    // },
    // methods: {
    //     sendEmail(e) {
    //         try {
    //             emailjs.sendForm('service_lp9ei4m', 'template_aif2mvn', e.target, 'user_whqAVDxlOR8N4y9MOkwQh', {
    //                 name: this.name,
    //                 email: this.email,
    //                 message: this.message
    //             })
    //             console.log('it works!!!')

    //         } catch(error) {
    //             console.log({error})
    //         }
    //         this.name = '',
    //         this.email = '',
    //         this.message = ''
    //     }
    // }
    // data() {
    //     return {
    //         fname: '',
    //         lname: '',
    //         phonenumber: '',
    //         id: '897367f7-d437-4a8a-bf1b-c829f5cf4d24',
    //         emailAddress: 'pitchayananpnoon@hotmail.com',
    //     }
    // },
    // methods: {
    //     onCreatePost() {
    //         axios
    //         .post(
    //             `https://api.mailslurp.com/createInbox?apiKey=${API_KEY}`,
    //         )
    //         .then((res) => res.data);

            
    //     }
    // }
}
</script>
<style scoped>
* {box-sizing: border-box;}

.container-form {
  display: block;
  margin:auto;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
}

label {
  float: left;
}

input[type=text], [type=email], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}
</style>